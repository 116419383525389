import React, { useEffect } from 'react'
import Pusher from 'pusher-js'


const App = () => {

  useEffect(() => {
    Pusher.logToConsole = false;
    const pusher = new Pusher('3c1871a869c5da6e469d', {
      cluster: 'mt1'
    });
    const channel = pusher.subscribe('user-1');
    channel.bind('payment-progress', function (data) {
      console.log(data);
    });
  }, [])
  return (
    <div>App</div>
  )
}

export default App